import React from 'react'
import logo from '../../images/giphy.gif'

const PlaceOrder = () => {
    return (
        <div>
           <img src={logo} alt=""/> 
        </div>
    )
}

export default PlaceOrder
