import React from 'react'


const Inventory = () => {
    return (
        <div>
            <h1>Inventory</h1>
            {/* <img src={gipy} alt=""/> */}
        </div>
    )
}

export default Inventory
