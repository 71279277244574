import React from 'react'

const PageNotFound = () => {
    return (
        <div>
            <h1>4040</h1>
            <h3>Page Not Found</h3>
        </div>
    )
}

export default PageNotFound
